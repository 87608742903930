<template>
  <div class="request">
    <div class="searchCondition">
      <div class="searchConditionItem">
        发布环境：
        <a-select
          v-model="environmentId"
          placeholder="请选择发布环境"
          allowClear
          @change="envChange"
        >
          <a-select-option v-for="i in environmentIdList" :key="i.id">
            {{ i.name }}
          </a-select-option>
        </a-select>
      </div>
      <div class="searchConditionItem">
        应用名称：
        <a-select
          v-model="projectId"
          placeholder="请选择应用名称"
          allowClear
          showSearch
          option-filter-prop="children"
          @change="projectChange"
        >
          <a-select-option v-for="i in projectIdList" :key="i.id">
            {{ i.enName }}
          </a-select-option>
        </a-select>
      </div>
      <div class="searchConditionItem">
        状态：
        <a-select
          v-model="requestStatus"
          placeholder="请选择状态"
          @change="requestStatusChange"
        >
          <a-select-option v-for="i in statusList" :key="i.id" :value="i.value">
            {{ i.label }}
          </a-select-option>
        </a-select>
      </div>
      <div class="searchConditionItem">
        申请时间：
        <a-range-picker
          v-model="rangeTime"
          format="YYYY-MM-DD"
          :ranges="range"
          style="width: 250px"
          @change="rangeChange"
        ></a-range-picker>
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
        <a-button type="primary" @click="add()" icon="plus">新建发布</a-button>
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(columns)"
      :columns="columns"
      :dataSource="dataSource"
      :pagination="pagination"
      :loading="loading"
      size="small"
    >
      <span slot="id" slot-scope="text, record" style="width: 100%">
        <span style="font-weight: bold">{{ text }}</span>
      </span>
      <span slot="projectName" slot-scope="text, record" style="width: 100%">
        <a-tooltip>
          <template slot="title"> {{ record.environmentName }} </template>
          <div
            class="rage"
            style="background-color: #f5222d"
            v-if="record.environmentName == '生产环境'"
          ></div>
          <div
            class="rage"
            style="background-color: #52c41a"
            v-else-if="record.environmentName == '测试环境'"
          ></div>
          <div class="rage" style="background-color: #dddddd" v-else></div>
        </a-tooltip>
        {{ text }}
      </span>
      <span
        slot="name"
        slot-scope="text, record"
        style="
          display: inline-block;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
        "
      >
        <span :title="text">{{ text }}</span>
      </span>
      <span slot="gitBranch" slot-scope="text, record" style="width: 100%">
        <a-icon type="branches" />{{ text }}#{{ record.gitCommitId }}
      </span>
      <span slot="status" slot-scope="text, record" style="width: 100%">
        <a-tag :color="record.tagColor">
          {{ text }}
          <a-icon type="loading" v-if="record.status === 'DELIVERING'" />
        </a-tag>
      </span>
      <span slot="action" slot-scope="text, record" style="width: 100%">
        <div v-if="record.status === 'WAIT_AUDIT'">
          <a-popconfirm
            placement="right"
            okText="通过"
            cancelText="驳回"
            @confirm="auditConfirm(record)"
            @cancel="auditConfirmCancel(record)"
          >
            <template slot="title">选择审核状态</template>
            <a href="javascript:;">审核</a>
          </a-popconfirm>
        </div>
        <div v-else-if="record.status === 'WAIT_DELIVERY'">
          <a-popconfirm
            placement="right"
            okText="确认"
            cancelText="取消"
            @confirm="deliveryNew(record)"
            :disabled="record.pipelineTaskId <= 0"
          >
            <template slot="title">
              确定要编译并发布{{ record.title }}?
            </template>
            <a href="javascript:;" :disabled="record.pipelineTaskId <= 0"
              >编译并发布</a
            >
          </a-popconfirm>
          <a-divider type="vertical" />
          <a-popconfirm
            placement="right"
            okText="确认"
            cancelText="取消"
            @confirm="revokeDelivery(record)"
          >
            <template slot="title">
              确定要撤销发布{{ record.title }}?
            </template>
            <a href="javascript:;" style="color: #ff4d4f">撤销发布</a>
          </a-popconfirm>
        </div>
        <div v-else>
          <a
            href="javascript:;"
            @click="checkClick(record)"
            :disabled="record.pipelineTaskId <= 0"
            >查看</a
          >
          <a-divider type="vertical" />
          <a
            href="javascript:;"
            @click="clickVisibleAdd(record)"
            :disabled="record.pipelineTaskId <= 0"
            >重新申请</a
          >
          <a-divider type="vertical" />
          <a-dropdown>
            <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
              其它操作 <a-icon type="down" />
            </a>
            <a-menu slot="overlay">
              <a-menu-item>
                <a
                  href="javascript:;"
                  @click="yamlDetail(record)"
                  :disabled="!record.yaml"
                  >查看yaml</a
                >
              </a-menu-item>
              <a-menu-item>
                <a-popconfirm
                  placement="right"
                  okText="确认"
                  cancelText="取消"
                  @confirm="deleteClick(record)"
                >
                  <template slot="title"
                    >确认是否删除{{ record.title }}</template
                  >
                  <a href="javascript:;" style="color: #ff4d4f">删除</a>
                </a-popconfirm>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
      </span>
    </a-table>
    <!-- 新建发布 -->
    <a-modal
      title="新建发布"
      v-model="addPostApplicationShow"
      :maskClosable="false"
      width="1000px"
      :footer="null"
    >
      <a-tabs v-model="active" @change="activeChange">
        <a-tab-pane key="1" tab="最近更新">
          <div style="margin-bottom: 10px">
            <a-input
              v-model.trim="enName"
              placeholder="请输入英文名称搜索"
              style="width: 200px"
              @pressEnter="add()"
              @change="allowClearChange"
              allowClear
            />
          </div>
          <a-table
            :rowClassName="$common.rowClassColor"
            bordered
            :components="$common.getTitle(recentlyProjectColumns)"
            :columns="recentlyProjectColumns"
            :dataSource="recentlyProjectList"
            size="small"
          >
            <span slot="enName" slot-scope="text, record" style="width: 100%">
              <a-tooltip>
                <template slot="title"> {{ record.cnName }} </template>
                {{ text }}
              </a-tooltip>
            </span>
            <span slot="action" slot-scope="text, record" style="width: 100%">
              <a href="javascript:;" @click="publishListCheck(record)"
                >发布列表</a
              >
            </span>
          </a-table>
        </a-tab-pane>
        <a-tab-pane key="2" tab="所有项目">
          <div style="margin-bottom: 10px">
            <a-input
              v-model="enName"
              placeholder="请输入英文名称搜索"
              style="width: 200px"
              @pressEnter="getAllProject()"
              @change="allowClearAllChange"
              allowClear
            />
          </div>
          <a-table
            :rowClassName="$common.rowClassColor"
            bordered
            :components="$common.getTitle(allProjectColumns)"
            :columns="allProjectColumns"
            :dataSource="allProjectList"
            :pagination="projectPagination"
            size="small"
          >
            <span slot="action" slot-scope="text, record" style="width: 100%">
              <a href="javascript:;" @click="publishListCheck(record)"
                >发布列表</a
              >
            </span>
          </a-table>
        </a-tab-pane>
      </a-tabs>
    </a-modal>

    <a-modal
      :title="'发布列表-' + projectName"
      v-model="publishListShow"
      :maskClosable="false"
      width="1000px"
      :footer="null"
    >
      <a-table
        :rowClassName="$common.rowClassColor"
        bordered
        :components="$common.getTitle(innerColumns)"
        :columns="innerColumns"
        :data-source="innerData"
        :pagination="false"
        size="small"
      >
        <span slot="is_audit" slot-scope="text" style="width: 100%">
          <a-tag v-if="!text" color="red">关闭</a-tag>
          <a-tag v-if="text" color="green">开启</a-tag>
        </span>
        <span slot="action" slot-scope="text, record" style="width: 100%">
          <a href="javascript:;" @click="addApplicationApply(record)"
            >新建发布申请</a
          >
        </span>
      </a-table>
    </a-modal>

    <!-- 选择应用 -->
    <a-modal
      title="选择应用"
      v-model="selectAppShow"
      :maskClosable="false"
      :afterClose="selectAppClose"
      :footer="null"
      width="800px"
      class="app_modal"
    >
      <div class="app_style">
        <a-menu class="app_menu" v-model="selectKey">
          <a-menu-item v-for="item in appList" :key="item.value">{{
            item.label
          }}</a-menu-item>
        </a-menu>
        <div class="app_content">
          <div class="header">
            <div class="title">huixiao</div>
            <div>
              <a-input-search
                placeholder="请输入快速搜应用"
                style="width: 200px"
                v-model="appName"
                @input="onAppSearch(appName)"
              />
            </div>
          </div>
          <div class="content">
            <a-button
              type="primary"
              v-for="item in appNameShowList"
              :key="item.id"
              @click="requestShowClick"
            >
              {{ item.name }}
            </a-button>
          </div>
        </div>
      </div>
    </a-modal>

    <a-modal
      :title="title"
      v-model="yamlVisible"
      :maskClosable="false"
      width="800px"
    >
      <div style="height: 500px">
        <codemirror
          v-model="yamlJsonData"
          :options="options"
          class="code"
        ></codemirror>
      </div>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button type="primary" @click="reDeliveryNew">重新发布</a-button>
          <a-button key="back" @click="yamlVisible = false">关闭</a-button>
        </div>
      </template>
    </a-modal>

    <!-- 新建发布申请 -->
    <newRequest
      v-if="requestShow"
      :show="requestShow"
      :requestTitle="requestTitle"
      :configId="configId"
      :requestData="newRelease"
      :branchList="branchList"
      :branchInfo="branchInfo"
      @refresh="branchListRefresh"
      @submit="requestClose"
      @cancel="requestClose"
      :projectId="projectId"
    ></newRequest>
  </div>
</template>
<script>
import * as api from "../lib/request";
import {
  listRepositoryBranches,
  projectList,
  deliveryConfigList,
} from "../lib/projectList.js";
import { serverList } from "../lib/serverList.js";
import { compileServerList } from "../lib/otherDeploy";
import { environmentList } from "../lib/environment.js";
import newRequest from "../pages/newRequest";
import moment from "moment";

export default {
  name: "postApplication",
  components: {
    newRequest,
  },
  data() {
    return {
      branchInfo: "",
      requestTitle: "",
      newRelease: {
        appTitle: "",
        commitInfo: "",
        branchGit: undefined,
        commitId: undefined,
        projectId: "",
      },
      loginEndOpen: false,
      branchList: [],
      configId: "",
      selectAppShow: false,
      addPostApplicationShow: false,
      columns: [
        {
          title: "申请编号",
          ellipsis: true,
          dataIndex: "id",
          scopedSlots: { customRender: "id" },
          width: 100,
          align: "center",
        },
        {
          title: "申请标题",
          ellipsis: true,
          dataIndex: "name",
          scopedSlots: { customRender: "name" },
          width: 700,
        },
        {
          title: "应用名称",
          ellipsis: true,
          dataIndex: "projectName",
          width: 200,
          scopedSlots: { customRender: "projectName" },
        },
        {
          title: "版本",
          ellipsis: true,
          dataIndex: "branch",
          scopedSlots: { customRender: "gitBranch" },
          width: 230,
        },
        {
          title: "状态",
          ellipsis: true,
          dataIndex: "statusLabel",
          scopedSlots: { customRender: "status" },
          width: 100,
        },
        {
          title: "申请人",
          ellipsis: true,
          dataIndex: "userName",
          width: 80,
        },
        {
          title: "申请日期",
          ellipsis: true,
          dataIndex: "gmtCreated",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 200,
        },
      ],
      dataSource: [],
      pagination: {},
      pageNo: 1,
      environmentId: undefined,
      projectId: undefined,
      newProjectId: undefined,
      environmentIdList: [],
      projectIdList: [],
      requestStatus: "ALL",
      loading: false,
      appName: "",
      appList: [],
      selectKey: [],
      requestShow: false,
      appNameList: [],
      appNameShowList: [],
      hostList: [],
      statusList: [
        { id: 1, label: "全部", value: "ALL" },
        { id: 2, label: "等待审核", value: "WAIT_AUDIT" },
        { id: 3, label: "等待发布", value: "WAIT_DELIVERY" },
        { id: 4, label: "发布成功", value: "SUCCESS" },
        { id: 5, label: "发布失败", value: "FAILURE" },
        { id: 6, label: "发布中", value: "DELIVERING" },
      ],
      rangeTime: [],
      range: {
        今天: [moment(), moment()],
        本周: [moment().weekday(0), moment().weekday(4)],
      },
      active: "1",
      recentlyProjectList: [],
      allProjectList: [],
      recentlyProjectColumns: [
        {
          title: "英文名称",
          ellipsis: true,
          dataIndex: "enName",
          scopedSlots: { customRender: "enName" },
        },
        {
          title: "分支",
          ellipsis: true,
          dataIndex: "branch",
        },
        {
          title: "提交日志",
          ellipsis: true,
          dataIndex: "message",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      allProjectColumns: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "id",
          width: 60,
        },
        {
          title: "英文名称",
          ellipsis: true,
          dataIndex: "enName",
        },
        {
          title: "中文名称",
          ellipsis: true,
          dataIndex: "cnName",
        },
        {
          title: "项目类型",
          ellipsis: true,
          dataIndex: "projectType",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      projectPagination: {},
      projectPageNo: 1,
      projectName: "",
      publishListShow: false,
      innerData: [],
      innerColumns: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "key",
          width: 60,
        },
        {
          title: "发布环境",
          ellipsis: true,
          dataIndex: "environment",
        },
        {
          title: "发布审核",
          ellipsis: true,
          dataIndex: "needAudit",
          scopedSlots: { customRender: "is_audit" },
        },
        {
          title: "操作",
          key: "action",
          scopedSlots: { customRender: "action" },
        },
      ],
      enName: "",
      queryStatusTimer: null,
      requestId: "",
      title: "",
      yamlVisible: false,
      yamlJsonData: {},
      options: {
        mode: { name: "javascript", json: true },
        readOnly: true,
        lineNumbers: true,
      },
    };
  },
  watch: {
    statusObj: {
      deep: true,
      handler(newVal) {
        this.dataSource.forEach((item) => {
          if (item.id == newVal.id) {
            this.$set(item, "status", newVal.status);
            this.$set(
              item,
              "statusLabel",
              this.statusTransformation(newVal.status).label
            );
            this.$set(
              item,
              "tagColor",
              this.statusTransformation(newVal.status).color
            );
          }
        });
      },
    },
  },
  computed: {
    formatDateEndTime() {
      // 开始时间
      if (this.rangeTime[1]) {
        return this.$common.dayFormat(this.rangeTime[1]) + " 23:59:59";
      }
      return "";
    },
    formatDateStartTime() {
      // 结束时间
      if (this.rangeTime[0]) {
        return this.$common.dayFormat(this.rangeTime[0]) + " 00:00:00";
      }
      return "";
    },
  },
  mounted() {
    let startTime = new Date(new Date() - 1 * 24 * 3600 * 1000);
    let endTime1 = new Date();
    let endTime = new Date(endTime1 + 1 * 24 * 3600 * 1000);
    this.rangeTime = [
      moment(startTime, "YYYY-MM-DD"),
      moment(endTime, "YYYY-MM-DD"),
    ];
    this.getProject();
    this.getEnvironment();
    this.getServerList();
  },
  methods: {
    // 新建发布申请
    addApplicationApply(val) {
      this.configId = val.id;
      this.requestShow = true;
      this.newProjectId = val.projectId;
      this.requestTitle =
        "新建发布申请 - " + this.projectName + " - " + val.environment;
      api.configDetailList({ id: val.id }).then((res) => {
        if (res.result === 200) {
          this.newRelease = {
            appTitle: "",
            commitInfo: "",
            branchGit: undefined,
            commitId: undefined,
            projectId: res.data.projectId,
            environmentId: res.data.environmentId,
          };
        }
      });
      setTimeout(() => {
        this.branchListRefresh1();
      }, 200);
    },
    publishListCheck(val) {
      this.projectName = val.enName;
      this.newProjectId = val.id;
      this.getEnvironment();
    },
    getDeliveryConfigList() {
      deliveryConfigList({ projectId: this.newProjectId }).then((res) => {
        if (res.result === 200) {
          let list = res.data;
          list.forEach((item, index) => {
            item.key = index + 1;
            this.environmentIdList.forEach((menu) => {
              if (item.environmentId === menu.id) {
                item.environment = menu.name;
              }
            });
          });
          this.innerData = list;
          this.publishListShow = true;
        }
      });
    },
    allowClearAllChange(e) {
      if (e.target.value) {
        return;
      }
      this.getAllProject(1);
    },
    getAllProject(index) {
      if (index) {
        this.projectPageNo = index;
      }
      let data = {
        pageNo: this.projectPageNo,
        pageSize: 10,
        name: this.enName,
      };
      projectList(data).then((res) => {
        if (res.result === 200) {
          this.allProjectList = res.data.list;
          this.projectPagination = {
            showQuickJumper: true,
            showTotal: () => `共${res.data.count}条`,
            pageSize: data.pageSize,
            current: data.pageNo,
            total: res.data.count,
            onChange: (current) => this.changePageProjectItem(current),
          };
        }
      });
    },
    changePageProjectItem(index) {
      this.getAllProject(index);
    },
    activeChange(key) {
      this.enName = "";
      if (key == "1") {
        this.add();
      } else {
        this.getAllProject();
      }
    },
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.add();
    },
    add() {
      api.recentlyProjectList({ name: this.enName }).then((res) => {
        if (res.result == 200) {
          this.recentlyProjectList = res.data;
          if (!this.addPostApplicationShow) {
            this.enName = "";
            this.addPostApplicationShow = true;
          }
        }
      });
    },
    rangeChange() {
      this.query(1);
    },
    // 选择发布环境
    envChange(val) {
      this.query(1);
    },
    // 选择用户
    projectChange(val) {
      this.query(1);
    },
    // 点击审核--通过
    auditConfirm(val) {
      let data = {
        id: val.id,
        passed: true,
      };
      this.auditRequest(data, "ok");
    },
    // 点击审核--驳回
    auditConfirmCancel(val) {
      let data = {
        id: val.id,
        passed: false,
      };
      this.auditRequest(data, "cancel");
    },
    // 审核操作
    auditRequest(data, key) {
      api.deliveryRequestAudit(data).then((res) => {
        if (res.result === 200) {
          this.query();
          if (key === "ok") {
            this.$message.success("审核已通过");
          } else {
            this.$message.success("审核已驳回");
          }
        }
      });
    },
    // 点击分支刷新
    branchListRefresh() {
      api.synGitCommitLog({ projectId: this.newProjectId }).then((res) => {
        if (res.result === 200) {
          this.branchListRefresh1();
        }
      });
    },
    branchListRefresh1() {
      this.branchList = [];
      listRepositoryBranches({ projectId: this.newProjectId }).then((res) => {
        if (res.result === 200) {
          let list = res.data;
          list.forEach((item) => {
            this.branchList.push({
              branchName: item.branchName,
            });
            item.gitDate = this.$common.transformTime(
              item.commitInfo.committedDateStr.replace(/-/g, "/")
            );
          });
        }
      });
    },
    // 点击重新申请
    clickVisibleAdd(val) {
      this.branchInfo = val.branch;
      this.newProjectId = val.projectId;
      this.configId = val.configId;
      this.requestShow = true;
      this.requestTitle = val.projectName + " - " + val.environmentName;
      api.configDetailList({ id: val.configId }).then((res) => {
        if (res.result === 200) {
          this.newRelease = {
            appTitle: "",
            commitInfo: "",
            branchGit: undefined,
            commitId: undefined,
            projectId: val.projectId,
            environmentId: val.environmentId,
          };
        }
      });
      setTimeout(() => {
        this.branchListRefresh1();
      }, 200);
    },
    // 确定选择应用
    requestShowClick() {
      this.requestShow = true;
      this.selectAppShow = false;
    },
    // 关闭新建申请发布
    requestClose(cancelable) {
      if (!cancelable) {
        this.publishListShow = false;
        this.addPostApplicationShow = false;
      }
      this.requestShow = false;
      this.branchInfo = "";
      this.newRelease = {
        appTitle: "",
        commitInfo: "",
        branchGit: undefined,
        commitId: undefined,
        projectId: "",
      };
      this.query();
    },
    // 快速搜应用
    onAppSearch(queryString) {
      let restaurants = this.appNameList;
      let outputList = this.appNameList
        .map((item) => {
          if (item.name.indexOf(queryString) > -1) {
            return item;
          }
        })
        .filter((item, i, self) => item && self.indexOf(item) === i);
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.appNameShowList = outputList;
      }, 200);
    },
    // 关闭选择应用
    selectAppClose() {
      this.appList = [];
      this.appNameList = [];
      this.selectKey = [];
    },
    // 选择发布状态查询
    requestStatusChange(val) {
      this.query(1);
    },
    // 选择截止日期
    closingDateChange(date, dateString) {
      // console.log(date, dateString);
    },
    // 点击查看
    checkClick(val) {
      let query = {
        id: val.id,
        name: val.projectName + " - " + val.environmentName,
        status: val.status,
        serverIdList: val.serverIdList,
        deliveryServerIdList: val.deliveryServerIdList,
        needResourcesCheck: val.needResourcesCheck,
        configId: val.configId,
        projectId: val.projectId,
        environmentId: val.environmentId,
        newProjectId: val.projectId,
        branchInfo: val.branch,
      };
      sessionStorage.setItem("request_data", JSON.stringify(query));
      this.$router.push({
        path: "/postingDetails",
        query: {
          id: val.pipelineTaskId,
          requestId: val.id,
        },
      });
    },
    deliveryNew(val) {
      api
        .deliveryRequestDeliveryNew({
          id: val.id,
          deliveryAction: "COMPILE_AND_DELIVERY",
        })
        .then((res) => {
          if (res.result === 200) {
            this.$message.success("发布已提交");
            this.query();
          }
        });
    },
    // 点击撤销发布
    revokeDelivery(val) {
      api.cancelDeliveryRequest({ id: val.id }).then((res) => {
        if (res.result === 200) {
          this.$message.success("撤销发布已提交");
          this.query();
        }
      });
    },
    yamlDetail(val) {
      this.title = val.name;
      this.yamlVisible = true;
      this.requestId = val.id;
      this.yamlJsonData = val.yaml;
    },
    reDeliveryNew() {
      let data = {
        id: this.requestId,
      };
      api.reDeliveryNew(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("重新发布成功");
          this.yamlVisible = false;
          this.query();
        }
      });
    },
    // 点击删除
    deleteClick(val) {
      let data = {
        requestIdList: [val.id],
      };
      api.deleteDeliveryRequest(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
    // 点击查询
    query(index) {
      let status = "";
      if (this.requestStatus !== "ALL") {
        status = this.requestStatus;
      }
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        environmentId: this.environmentId,
        projectId: this.projectId,
        status: status,
        gmtCreatedStart: this.formatDateStartTime,
        gmtCreatedEnd: this.formatDateEndTime,
      };
      this.getDeliveryLog(data);
    },
    // 查询发布日志列表
    getDeliveryLog(data) {
      this.loading = true;
      api
        .deliveryRequestList(data)
        .then((res) => {
          this.loading = false;
          if (res.result == 200) {
            let list = res.data.records;
            list.forEach((item, i) => {
              item.key = i + 1;
              item.gitCommitId = item.commitId.substr(0, 8);
              item.statusLabel = this.statusTransformation(item.status).label;
              item.tagColor = this.statusTransformation(item.status).color;
              item.name = item.title;
            });
            this.dataSource = list;
            let idList = this.dataSource
              .filter((item) => {
                return item.status == "DELIVERING";
              })
              .map((item) => {
                return item.id;
              });
            if (idList.length > 0) {
              this.queryRefreshStatus(idList);
            }
            this.pagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: res.data.pageSize,
              current: res.data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    queryRefreshStatus(idList) {
      api
        .queryStatus({ ids: idList.join(",") })
        .then((res) => {
          if (res.result == 200) {
            let newIdList = [];
            for (const key in res.data) {
              const status = res.data[key];
              if (status == "DELIVERING") {
                newIdList.push(key);
              } else {
                this.dataSource.forEach((item) => {
                  if (key == item.id) {
                    this.$set(item, "status", status);
                    this.$set(
                      item,
                      "statusLabel",
                      this.statusTransformation(item.status).label
                    );
                    this.$set(
                      item,
                      "tagColor",
                      this.statusTransformation(item.status).color
                    );
                  }
                });
              }
            }
            if (this.queryStatusTimer) {
              clearTimeout(this.queryStatusTimer);
              this.queryStatusTimer = null;
            }
            if (newIdList.length > 0) {
              this.queryStatusTimer = setTimeout(() => {
                this.queryRefreshStatus(newIdList);
              }, 2000);
            }
          }
        })
        .catch(() => {
          if (this.queryStatusTimer) {
            clearTimeout(this.queryStatusTimer);
            this.queryStatusTimer = null;
          }
          this.queryStatusTimer = setTimeout(() => {
            this.queryRefreshStatus(idList);
          }, 2000);
        });
    },
    // 分页
    changePageItem(current) {
      this.query(current);
    },
    // 查询发布环境列表
    getEnvironment() {
      let data = {
        pageNo: 1,
        pageSize: 1000,
      };
      environmentList(data).then((res) => {
        if (res.result === 200) {
          this.environmentIdList = res.data.records;
          if (this.newProjectId) {
            this.getDeliveryConfigList();
          }
        }
      });
    },
    // 查询环境列表
    getServerList() {
      let data = {
        pageNo: 1,
        pageSize: 1000,
      };
      serverList(data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          list.forEach((item) => {
            item.disabled = false;
          });
          this.hostList = list;
          this.query(1);
        }
      });
    },
    // 查询应用名称列表
    getProject() {
      let data = {
        pageNo: 1,
        pageSize: 1000,
      };
      projectList(data).then((res) => {
        if (res.result === 200) {
          this.projectIdList = res.data.list;
        }
      });
    },
    // 状态转换
    statusTransformation(val) {
      let res = {
        label: "",
        color: "",
      };
      switch (val) {
        case "WAIT_AUDIT":
          res = {
            label: "等待审核",
            color: "orange",
          };
          break;
        case "WAIT_DELIVERY":
          res = {
            label: "已就绪",
            color: "orange",
          };
          break;
        case "DELIVERING":
          res = {
            label: "发布中",
            color: "orange",
          };
          break;
        case "SUCCESS":
          res = {
            label: "发布成功",
            color: "green",
          };
          break;
        case "FAILURE":
          res = {
            label: "发布失败",
            color: "red",
          };
          break;
        case "CANCELED":
          res = {
            label: "已取消",
            color: "red",
          };
          break;
        case "AUDIT_FAILURE":
          res = {
            label: "审核失败",
            color: "red",
          };
          break;
        case "COMPILED":
          res = {
            label: "等待发布",
            color: "orange",
          };
          break;
        case "ROLLBACK":
          res = {
            label: "已回滚",
            color: "orange",
          };
          break;
      }
      return res;
    },
  },
  beforeDestroy() {
    if (this.queryStatusTimer) {
      clearTimeout(this.queryStatusTimer);
      this.queryStatusTimer = null;
    }
  },
};
</script>
<style lang="scss" scoped>
.code {
  height: 100%;
  &:deep() .CodeMirror {
    height: 100% !important;
  }
}
.rage {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: red;
  display: inline-block;
}
.app_modal {
  ::v-deep .ant-modal-body {
    padding: 16px 0;
  }
  .app_style {
    display: flex;
    .app_menu {
      width: 200px;
      height: 480px;
    }
    .app_content {
      padding: 8px 40px;
      width: 100%;
      .header {
        display: flex;
        justify-content: space-between;
        .title {
          color: #000;
          font-size: 25px;
        }
      }
      .content {
        button {
          margin-top: 20px;
          width: 165px;
          height: 60px;
          font-size: 18px;
          margin-right: 20px;
        }
      }
    }
  }
}
</style>
